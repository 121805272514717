import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideRouter } from "@angular/router";
import { provideAnimations } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalService,
} from "@azure/msal-angular";

import { routes } from "./app.routes";
import { environment } from "@app/environments/environment";
import { EoSMemberManagementApiModule } from "./core/api";
import { ApiConfigurationFactory } from "./core/factories/api-configuration-factory";
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { AuthState } from "./core/store/auth/auth.state";

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        importProvidersFrom(EoSMemberManagementApiModule.forRoot(ApiConfigurationFactory)),
        importProvidersFrom(NgxsModule.forRoot([AuthState], { developmentMode: !environment.production })),
        importProvidersFrom(
            NgxsReduxDevtoolsPluginModule.forRoot({
                disabled: environment.production,
            }),
        ),
        importProvidersFrom(MsalGuard),
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
        { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
        { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
};

function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.msal_clientId,
            authority: environment.msal_authority,
            redirectUri: environment.msal_redirectUri,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
        system: {
            allowNativeBroker: false,
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false,
            },
        },
    });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: { scopes: ["user.read"] },
    };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();

    protectedResourceMap.set(`${environment.baseUrl}/*`, [environment.apiAccessUri]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}
