export enum AuthActionTypes {
    GetCurrentUser = "[Auth] Get Current User",

    SignOut = "[Auth] Sign Out",
}

export class GetCurrentUserAction {
    static type = AuthActionTypes.GetCurrentUser;
}

export class SignOutAction {
    static type = AuthActionTypes.SignOut;
}
