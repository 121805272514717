import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";

import { MenuItem } from "primeng/api";
import { MenuModule } from "primeng/menu";

import { fadeInAnimation } from "@app/shared/index";
import { AuthFacade } from "@app/core/store/auth/auth.facade";

@Component({
    selector: "app-header",
    standalone: true,
    imports: [CommonModule, MenuModule],
    templateUrl: "./header.component.html",
    styleUrl: "./header.component.scss",
    animations: [fadeInAnimation],
})
export class HeaderComponent {
    userName$ = this.authFacade.userName$;

    userInitials$ = this.authFacade.userInitials$;

    constructor(private authFacade: AuthFacade) {}

    public readonly items: MenuItem[] = [
        {
            id: "sign-out",
            label: "Sign out",
            command: () => this._signOut(),
        },
    ];

    /**
     * Makes the sign-out request to the API
     * @returns {void}
     */
    private _signOut(): void {
        this.authFacade.signOut();
    }
}
