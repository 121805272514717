<div class="grid min-h-screen grid-rows-header-and-content">
    <div class="header-mobile h-full w-full">
        <app-header></app-header>
    </div>

    <div class="grid grid-cols-nav-and-content">
        <aside class="w-56 h-screen-without-header">
            <app-nav></app-nav>
        </aside>

        <main class="h-screen-without-header mb-16 px-6 xl:px-28 overflow-y-scroll overflow-x-auto xl:overflow-x-hidden" id="main">
            <div class="mt-12 mb-16 mx-auto max-w-75xl sm:min-w-256 xl:min-w-0 mobile:max-w-320">
                <ng-content></ng-content>
            </div>
        </main>
    </div>
</div>

<p-toast
    [autoZIndex]="false"
    position="top-center"
    showTransitionOptions="200ms ease-out"
    hideTransitionOptions="200ms ease-in"
    showTransformOptions="translateY(-100%)"
    hideTransformOptions="translateY(100%)"
>
    <ng-template let-message pTemplate="message">
        <app-notification [notification]="message"></app-notification>
    </ng-template>
</p-toast>
