import { Selector } from "@ngxs/store";

import type { CurrentUser } from "@app/core/api";
import { AuthState } from "./auth.state";
import type { AuthStateModel } from "./auth.state.model";

export class AuthSelectors {
    @Selector([AuthState])
    static currentUser(state: AuthStateModel): CurrentUser | null {
        return state.currentUser;
    }

    @Selector([AuthState])
    static userName(state: AuthStateModel): string {
        if (state.currentUser !== null && state.currentUser.userName !== null) {
            return state.currentUser.userName!;
        }
        return "";
    }

    @Selector([AuthState])
    static isAdministrator(state: AuthStateModel): boolean {
        if (state.currentUser !== null) {
            return state.currentUser.isAdministrator!;
        }
        return false;
    }

    @Selector([AuthState])
    static userInitials(state: AuthStateModel): string {
        if (state.currentUser !== null && state.currentUser.userName !== null) {
            return state
                .currentUser!.userName!.match(/(\b\S)?/g)!
                .join("")
                .match(/(^\S|\S$)?/g)!
                .join("")
                .toUpperCase();
        }
        return "";
    }
}
