import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";


@Component({
    selector: "access-denied",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./access-denied.component.html",
    styleUrl: "./access-denied.component.scss",
})
export class AccessDeniedComponent  {

}
