import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthFacade } from "@app/core/store/auth/auth.facade";


@Component({
    selector: "dashboard",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./dashboard.component.html",
    styleUrl: "./dashboard.component.scss",
})
export class DashboardComponent  {

    userName$ = this.authFacade.userName$;
    
    constructor(private authFacade: AuthFacade) {}
}
