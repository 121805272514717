import { Component, Input } from "@angular/core";
import { INotification } from "./models";
import { CommonModule } from "@angular/common";

@Component({
    selector: "app-notification",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./notification.component.html",
    styleUrl: "./notification.component.scss",
})
export class NotificationComponent {
    @Input() notification!: INotification;
}
