<nav
    class="nav-mobile py-12 z-30 h-full bg-brand-neutral-20 flex flex-col"
    id="nav"
    [ngClass]="isMenuExpanded ? 'nav-mobile-expanded' : 'nav-mobile-collapsed'"
>
    <div [ngClass]="isMenuExpanded ? 'nav-mobile-btn-expanded' : 'nav-mobile-btn'" (click)="onExpandMenu()"></div>
    <div class="grid grid-cols-1 auto-rows-auto" id="nav-links" *ngIf="currentUser$ | async as currentUser; else skeletonLoader">
        <span class="link link-disabled" *ngIf="!currentUser.isAdministrator">Members</span>
        <a
            class="link"
            #clubsLink="routerLinkActive"
            [routerLink]="['members']"
            *ngIf="currentUser.isAdministrator"
            [queryParamsHandling]="getQueryParamsHandling(clubsLink)"
            [routerLinkActiveOptions]="{ exact: false }"
            routerLinkActive="link-active"
            >Members</a
        >

        <span class="link link-disabled" *ngIf="!currentUser.isAdministrator">Referrals</span>
        <a
            class="link"
            #membershipPlansLink="routerLinkActive"
            [routerLink]="['referral-records']"
            *ngIf="currentUser.isAdministrator"
            [queryParamsHandling]="getQueryParamsHandling(membershipPlansLink)"
            [routerLinkActiveOptions]="{ exact: false }"
            routerLinkActive="link-active"
            >Referrals</a
        >
    </div>
</nav>

<ng-template #skeletonLoader>
    <div class="grid grid-cols-1 auto-rows-auto">
        <p-skeleton class="w-full h-3 p-6 pb-10" *ngFor="let index of [0, 1]" @fadeIn styleClass="bg-gray-600"></p-skeleton>
    </div>
</ng-template>
