import { Injectable } from "@angular/core";
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { filter, map, Observable } from "rxjs";
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AuthFacade } from "../store/auth/auth.facade";

@Injectable({
    providedIn: "root",
})
export class AdministratorGuard {
    constructor(private authFacade: AuthFacade, private router: Router) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authFacade.currentUser$.pipe(
            filter((value) => value !== null),
            map((value) => {
                return value !== null
                    ? value.isAdministrator
                        ? true
                        : this.router.createUrlTree(["access-denied"])
                    : this.router.createUrlTree(["access-denied"]);
            }),
        );
    }
}
