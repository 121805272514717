import { Injectable } from "@angular/core";
import type { CurrentUser } from "@app/core/api";
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Select, Store } from "@ngxs/store";
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Observable } from "rxjs";
import { GetCurrentUserAction, SignOutAction } from "./auth.actions";
import { AuthSelectors } from "./auth.selectors";

@Injectable({ providedIn: "root" })
export class AuthFacade {   
    @Select(AuthSelectors.currentUser)
    public currentUser$!: Observable<CurrentUser | null>;

    @Select(AuthSelectors.userInitials)
    public userInitials$!: Observable<string>;

    @Select(AuthSelectors.userName)
    public userName$!: Observable<string>;

    @Select(AuthSelectors.isAdministrator)
    public isAdministrator$!: Observable<boolean>;

    constructor(private store: Store) {}

    isAdministratorSnapshot = () => this.store.selectSnapshot(AuthSelectors.isAdministrator);

    currentUser = () => this.store.selectSnapshot(AuthSelectors.currentUser);

    getCurrentUser = () => this.store.dispatch(new GetCurrentUserAction());

    signOut = () => this.store.dispatch(new SignOutAction());
}
