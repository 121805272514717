import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { QueryParamsHandling, Router, RouterLinkActive, RouterModule } from "@angular/router";

import { SkeletonModule } from "primeng/skeleton";

import { fadeInAnimation } from "@app/shared/index";
import { AuthFacade } from "@app/core/store/auth/auth.facade";

@Component({
    selector: "app-nav",
    standalone: true,
    imports: [CommonModule, RouterModule, SkeletonModule],
    templateUrl: "./nav.component.html",
    styleUrl: "./nav.component.scss",
    animations: [fadeInAnimation],
})
export class NavComponent {
    currentUser$ = this.authFacade.currentUser$;
    public isMenuExpanded: boolean = false;

    constructor(private router: Router, private authFacade: AuthFacade) {}

    public getQueryParamsHandling(link: RouterLinkActive): QueryParamsHandling | null {
        const hasChildRouteActive: boolean = this.router.routerState.snapshot.url.split("?")?.[0].split("/")?.length > 1 || false;

        return link.isActive && !hasChildRouteActive ? "preserve" : null;
    }

    public onExpandMenu(): void {
        this.isMenuExpanded = !this.isMenuExpanded;
    }
}
