<div class="flex flex-1">
  <div class="flex items-center justify-start"></div>

  <div class="flex-1 ml-6 mr-4">
    <p
      class="text-sm text-brand-neutral-20"
      [innerHTML]="notification.summary"
    ></p>
  </div>
</div>
