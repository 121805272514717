import { Component, OnDestroy, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { CommonModule } from "@angular/common";

import { MsalBroadcastService, MsalModule } from "@azure/msal-angular";
import { EventMessage, EventType } from "@azure/msal-browser";
import { Subject, filter, takeUntil } from "rxjs";

import { LayoutComponent } from "./core/components/layout/layout.component";
import { AuthFacade } from "./core/store/auth/auth.facade";

@Component({
    selector: "app-root",
    standalone: true,
    imports: [CommonModule, RouterOutlet, LayoutComponent, MsalModule],
    templateUrl: "./app.component.html",
    styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit, OnDestroy {
    title = "Eos-Member-Management-Frontend";
    private readonly _destroying$ = new Subject<void>();

    constructor(private msalBroadcastService: MsalBroadcastService, private authFacade: AuthFacade) {}

    ngOnInit(): void {
        this.authFacade.getCurrentUser();
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
                takeUntil(this._destroying$),
            )
            .subscribe((result: EventMessage) => {
                console.log(result.error);
            });
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE),
                takeUntil(this._destroying$),
            )
            .subscribe((result: EventMessage) => {
                console.log(result.error);
            });
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
                takeUntil(this._destroying$),
            )
            .subscribe((result: EventMessage) => {
                console.log(result.error);
            });
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.SSO_SILENT_FAILURE),
                takeUntil(this._destroying$),
            )
            .subscribe((result: EventMessage) => {
                console.log(result.error);
            });
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
