import { Inject, Injectable } from "@angular/core";
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Action, State, StateContext } from "@ngxs/store";

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UserService } from "@app/core/api";

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AuthStateModel, AUTH_FEATURE_KEY } from "./auth.state.model";
import { GetCurrentUserAction, SignOutAction } from "./auth.actions";
import { catchError, tap } from "rxjs";
import { PublicClientApplication } from "@azure/msal-browser";
import { MSAL_INSTANCE } from "@azure/msal-angular";

@State<AuthStateModel>({
    name: AUTH_FEATURE_KEY,
    defaults: {
        currentUser: null,
    },
})
@Injectable({ providedIn: "root" })
export class AuthState {
    constructor(private userService: UserService, @Inject(MSAL_INSTANCE) private msal: PublicClientApplication) {}

    @Action(GetCurrentUserAction)
    getCurrentUser(ctx: StateContext<AuthStateModel>) {
        return this.userService.userCurrentGet().pipe(
            tap((value) => {
                ctx.patchState({ currentUser: value });
            }),
            catchError((err) => {
                throw err;
            }),
        );
    }

    @Action(SignOutAction)
    signOut(ctx: StateContext<AuthStateModel>) {
        this.msal.logoutRedirect()
    }
}
