import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";

import { HeaderComponent } from "../header/header.component";
import { NavComponent } from "../nav/nav.component";
import { NotificationComponent } from "../notification/notification.component";

@Component({
    selector: "app-layout",
    standalone: true,
    imports: [CommonModule, HeaderComponent, NavComponent, NotificationComponent, ToastModule],
    providers: [MessageService],
    templateUrl: "./layout.component.html",
    styleUrl: "./layout.component.scss",
})
export class LayoutComponent {}
