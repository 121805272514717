<header class="w-full h-20 px-6 xl:px-10 z-20 flex justify-between bg-black" id="header">
    <div class="flex-1"></div>

    <div class="flex items-center justify-center flex-1">
        <span class="uppercase font-bold text-white text-lg leading-6 text-center">MEMBER MANAGEMENT</span>
    </div>

    <div class="flex items-center justify-end flex-1">
        <button class="flex items-center cursor-pointer" id="user-menu-toggle" @fadeIn (click)="menu.toggle($event)" type="button">
            <span class="h-8 w-8 bg-brand-neutral-20 rounded border border-brand-white flex items-center justify-center text-sm text-brand-white font-medium">
                {{ userInitials$ | async }}
            </span>

            <span class="ml-3 text-sm text-brand-white font-medium xl:block">{{ userName$ | async }}</span>

            <span class="ml-2 flex items-center justify-center">
                <span class="icon" id="user-menu-chevron-icon" [class.icon-active]="menu.visible"></span>
            </span>
        </button>

        <p-menu #menu [popup]="true" [model]="items" showTransitionOptions="100ms ease-in" hideTransitionOptions="100ms ease-out"></p-menu>
    </div>
</header>
