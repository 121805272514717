import type { ConfigurationParameters } from "@app/core/api";
import { Configuration } from "@app/core/api";
import { environment } from "@app/environments/environment";

/**
 * ApiModule configuration
 * @returns {Configuration}
 * @constructor
 */
export const ApiConfigurationFactory = (): Configuration => {
    const params: ConfigurationParameters = {
        basePath: environment.baseUrl,
    };

    return new Configuration(params);
};
