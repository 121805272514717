import { importProvidersFrom } from "@angular/core";
import { Routes } from "@angular/router";

import { NgxsModule } from "@ngxs/store";

import { MembersState } from "./features/members/state/members/members.state";
import { PaginationState } from "./core/store/pagination";
import { DictionariesState } from "./core/store/dictionaries";
import { MessageService } from "primeng/api";
import { NotificationsService } from "./core/services/notifications";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { MemberState } from "./features/members/state/member/member.state";
import { ReferralsState } from "./features/referrals/state/referrals/referrals.state";
import { AdministratorGuard } from "./core/guards/administrator.guard";
import { AccessDeniedComponent } from "./core/containers/access-denied/access-denied.component";
import { DashboardComponent } from "./core/containers/dashboard/dashboard.component";
import { MsalGuard } from "@azure/msal-angular";

export const routes: Routes = [
    {
        path: "",
        component: DashboardComponent,
    },
    {
        path: "members",
        canActivate: [AdministratorGuard],
        loadComponent: () => import("./features/members/containers/members/members.component").then((mod) => mod.MembersComponent),
        providers: [
            importProvidersFrom(NgxsModule.forFeature([MembersState])),
            importProvidersFrom(NgxsModule.forFeature([PaginationState])),
            importProvidersFrom(NgxsModule.forFeature([DictionariesState])),
            importProvidersFrom(NgxsRouterPluginModule.forRoot()),
            MessageService,
            NotificationsService,
        ],
    },
    {
        path: "members/:id",
        canActivate: [AdministratorGuard],
        loadComponent: () => import("./features/members/containers/member-details/member-details.component").then((mod) => mod.MemberDetailsComponent),
        providers: [
            importProvidersFrom(NgxsModule.forFeature([MemberState])),
            importProvidersFrom(NgxsRouterPluginModule.forRoot()),
            MessageService,
            NotificationsService,
        ],
    },
    {
        path: "referral-records",
        canActivate: [AdministratorGuard],
        canActivateChild: [AdministratorGuard],
        loadComponent: () => import("./features/referrals/containers/referrals/referrals.component").then((mod) => mod.ReferralsComponent),
        providers: [
            importProvidersFrom(NgxsModule.forFeature([ReferralsState])),
            importProvidersFrom(NgxsModule.forFeature([PaginationState])),
            importProvidersFrom(NgxsModule.forFeature([DictionariesState])),
            importProvidersFrom(NgxsRouterPluginModule.forRoot()),
            MessageService,
            NotificationsService,
        ],
    },
    {
        path: "access-denied",
        canActivate: [MsalGuard],
        component: AccessDeniedComponent,
    },
    {
        path: "**",
        redirectTo: "",
    },
];
